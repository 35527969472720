$fontSize: 16px;
$smallFontSize: 13px;
$badgeFontSize: 14px;
$amountFontSize: 15px;

.pdf-wrapper {
  width: 100%;
  background: #ffffff;
  .pdf-container {
    margin: 0 auto;
    // width: 750px;
    width: 100%;
    padding: 0 60px;

    .bar-chart {
      position: relative;
      width: 100%;

      > div {
        position: relative;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.avoid-break {
  page-break-inside: avoid-page !important;
  break-inside: avoid-page !important;
}

.break-before {
  page-break-before: always !important;
  break-before: page !important;
}

/* header style */
.header-container {
  width: 100%;
  margin-bottom: 20px;
  border: 0;

  > tbody {
    border: 0;
    > tr {
      border: 0;
      > td {
        border: 0;
      }
    }
  }

  .header-title {
    font-family: Helvetica, "Lato", Arial;
    font-size: 28px;
    color: #1d1c1d;
    line-height: 28px;
    font-weight: 700;
    margin: 0;

    &.highlight {
      color: #41b4b4;
    }
  }
}

.header-info {
  font-family: Helvetica, "Lato", Arial;
  font-size: $fontSize;
  color: #1d1c1d;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
}

/* overview style */
.overview > table {
  width: 100%;
  border: 0;

  > tbody {
    background: #1d1c1d;
    border: 0;

    > tr {
      border: 0;
    }

    td {
      border: 0;
      padding: 10px;
      padding-bottom: 16px;

      p {
        font-family: Helvetica, "Lato", Arial;
        font-size: $fontSize;
        color: #ffffff;
        line-height: 9px;
        font-weight: 700;
        margin: 0;
        margin-top: 2px;

        &.small {
          margin-top: 6px;
          font-size: $smallFontSize;
          font-weight: 400;
        }

        &.type {
          font-weight: 400;
        }
      }

      div.types {
        height: 10px;
        width: 10px;
        border-radius: 1px;
        margin-right: 4px;
        position: relative;
        top: 1px;
      }

      .amount {
        font-family: Helvetica, "Lato", Arial;
        font-size: $amountFontSize;
        color: #ffffff;
        line-height: 9px;
        font-weight: 700;
        margin: 0;
        margin-right: 20px;
      }
    }
  }
}

/* table thead style */
.table {
  width: 100%;
  border: 0;

  > thead {
    background-color: #1d1c1d;
    border: 0;

    > tr {
      border: 0;
      > th {
        padding: 10px;
        padding-bottom: 16px;
        border: 0;

        > p {
          &.title {
            font-family: Helvetica, "Lato", Arial;
            font-size: $fontSize;
            color: #ffffff;
            line-height: 9px;
            font-weight: 700;
            margin: 0;
            text-transform: uppercase;

            > .badge {
              background-color: #ffffff;
              font-family: Helvetica, "Lato", Arial;
              font-size: $badgeFontSize;
              color: #000000;
              line-height: 9px;
              font-weight: 700;
              margin: 0;
              width: fit-content;
              border-radius: 10px;
              padding: 1px 6px;
              margin-left: 3px;
            }

            > .badge.blue-badge {
              background-color: #41b4b4;
              color: #ffffff;
            }

            > .badge.orange-badge {
              background-color: #ff6e6e;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

/* table details style */
.table-details {
  width: 100%;
  border: 0;

  > tbody {
    border: 0;
    > tr {
      border: 0;

      &.table-details-header {
        > td {
          > p {
            &.title {
              font-family: Helvetica, "Lato", Arial;
              font-size: $fontSize;
              color: #ffffff;
              line-height: 20px;
              font-weight: 700;
              margin: 0;
              text-transform: uppercase;

              > .badge {
                background-color: #ffffff;
                font-family: Helvetica, "Lato", Arial;
                font-size: $badgeFontSize;
                color: #000000;
                line-height: 9px;
                font-weight: 700;
                margin: 0;
                width: fit-content;
                border-radius: 10px;
                padding: 1px 6px;
                margin-left: 3px;
              }

              > .badge.blue-badge {
                background-color: #41b4b4;
                color: #ffffff;
              }

              > .badge.orange-badge {
                background-color: #ff6e6e;
                color: #ffffff;
              }
            }
          }
        }
      }

      > td {
        padding: 4px 10px;
        border: 0;
        border-bottom: 1px solid #dcdcdc;

        &:first-child {
          width: 30%;
          background-color: #ededed;
        }

        &:last-child {
          width: 70%;
          > p {
            font-weight: 400;
          }
        }

        &.table-details-head {
          background-color: #1d1c1d;
        }

        > p {
          font-family: Helvetica, "Lato", Arial;
          font-size: $fontSize;
          color: #1d1c1d;
          line-height: 20px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }
}

/* table list style */
.table-list {
  width: 100%;
  border: 0;

  > tbody {
    border: 0;
    > tr {
      border: 0;

      &.table-list-header {
        > td {
          background-color: #1d1c1d;
          > p {
            &.title {
              font-family: Helvetica, "Lato", Arial;
              font-size: $fontSize;
              color: #ffffff;
              line-height: 20px;
              font-weight: 700;
              margin: 0;
              text-transform: uppercase;

              > .badge {
                background-color: #ffffff;
                font-family: Helvetica, "Lato", Arial;
                font-size: $badgeFontSize;
                color: #000000;
                line-height: 9px;
                font-weight: 700;
                margin: 0;
                width: fit-content;
                border-radius: 10px;
                padding: 1px 6px;
                margin-left: 3px;
              }

              > .badge.blue-badge {
                background-color: #41b4b4;
                color: #ffffff;
              }

              > .badge.orange-badge {
                background-color: #ff6e6e;
                color: #ffffff;
              }
            }
          }
        }
      }

      &.head td {
        background-color: #ededed;
        > p {
          font-weight: 700;
        }
      }
      &.head-title td {
        background-color: #1d1c1d;
        > p {
          color: #ffffff;
        }
      }

      td {
        padding: 4px 10px;
        border: 0;
        border-bottom: 1px solid #dcdcdc;
        &.right {
          padding-right: 30px;
        }
        &:last-child.right {
          padding-right: 50px;
        }

        > p {
          font-family: Helvetica, "Lato", Arial;
          font-size: $fontSize;
          color: #1d1c1d;
          line-height: 20px;
          font-weight: 400;
          margin: 0;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}

.table-detail-list {
  width: 100%;
  border: 0;

  > tbody {
    border: 0;
    > tr {
      border: 0;

      &.head-title > td {
        background-color: #1d1c1d;

        > p {
          color: #ffffff;
          font-weight: 700;
        }
      }

      > td {
        padding: 4px 10px;
        border: 0;
        border-bottom: 1px solid #dcdcdc;

        &.amount {
          width: 180px;

          &:last-child {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

/* lender table style */
.lender-table {
  width: 100%;
  border: 0;

  > tbody {
    border: 0;

    > tr {
      border: 0;
    }

    > .lender-title {
      border: 0;

      > td {
        border: 0;

        &:last-child {
          border: 0;
        }
      }
    }

    > .lender-header {
      td {
        border: 0;

        &:first-child {
          border-left: 1px solid #1d1c1d;
        }

        &:last-child {
          border-right: 1px solid #1d1c1d;
        }

        .info {
          font-style: italic;
          font-weight: 400;
        }

        > p {
          font-family: Helvetica, "Lato", Arial;
          font-size: $fontSize;
          color: #1d1c1d;
          line-height: 9px;
          font-weight: 700;
          margin: 0;

          &.lender-name {
            font-style: normal;
            white-space: nowrap;
            border: 0;

            img {
              height: 18px;
              display: inline-block;
              margin-right: 5px;
            }

            > span {
              display: inline-block;
            }
          }
        }
      }
    }

    > tr {
      border: 1px solid #1d1c1d;

      > td {
        padding: 0;
        border: 0;

        > .lender-details {
          height: 100%;
          padding-left: 1px;
          padding-top: 1px;
          border-left: 1px solid #1d1c1d;
        }

        &.lender-type {
          border: 0;
          vertical-align: text-top;

          &.error {
            padding-top: 10px;
            background: #f2f2f2;
          }

          &.has-error > p {
            color: #ff6e6e;
          }

          &.lender-lem > p {
            color: #1d1c1d;
          }

          > p {
            font-family: Helvetica, "Lato", Arial;
            font-size: $fontSize;
            color: #2dbfc1;
            line-height: $fontSize;
            font-weight: 700;
            margin: 0;
            margin-top: 2px;
            height: auto;
            padding: 0 5px;

            &.error {
              color: #ff6e6e;
              margin-bottom: 10px;
              white-space: nowrap;

              > svg {
                display: inline;
              }

              > span {
                margin-left: 5px;
                display: inline;
              }
            }
          }
        }

        > p {
          font-family: Helvetica, "Lato", Arial;
          font-size: $fontSize;
          color: #1d1c1d;
          line-height: 9px;
          font-weight: 700;
          margin: 0;

          &.lender-name {
            text-align: left;
            font-style: normal;
            white-space: nowrap;
            border: 0;
            border-bottom: 1px solid #1d1c1d;
            padding: 10px;
            padding-bottom: 16px;
            height: 44px;
            max-height: 44px;

            img {
              height: 18px;
              display: inline-block;
              margin-right: 5px;
            }

            > span {
              display: inline-block;
              color: #1d1c1d;
            }
          }

          &.lender-info {
            text-align: right;
            font-style: italic;
            font-weight: 400;
            border: 0;
            padding: 16px 10px;
            height: 45px;
            max-height: 45px;
          }
        }
      }
    }
  }
}

.lender-errors {
  > p {
    padding: 10px;
    padding-bottom: 16px;
    background-color: #f2f2f2;
    font-family: Helvetica, "Lato", Arial;
    font-size: $fontSize;
    line-height: 20px;
    font-style: italic;
    font-weight: 400;

    &.error {
      color: #ff6e6e;
    }

    &.warning {
      color: #f18931;
    }

    img,
    svg {
      display: inline;
      margin-right: 5px;
    }

    > span {
      display: inline;
    }
  }
}

/* lender table list style */
.lender-table-list > table {
  width: 100%;
  border: 0;

  > tbody {
    border: 0;
    > tr {
      border: 0;

      &.table-header {
        > td {
          border: 0;
          padding: 10px;
          padding-bottom: 16px;
          background: #1d1c1d;

          > p {
            font-family: Helvetica, "Lato", Arial;
            font-size: $fontSize;
            color: #ffffff;
            line-height: 9px;
            font-weight: 700;
            margin: 0;
            white-space: nowrap;
            vertical-align: text-top;

            > span {
              margin-left: 4px;
            }
          }
        }
      }

      &.lender-error > td:last-child {
        background: #fbf1f1;
      }

      > td {
        padding: 4px 10px;
        border: 0;
        border-bottom: 1px solid #dcdcdc;

        > p {
          font-family: Helvetica, "Lato", Arial;
          font-size: $fontSize;
          color: #1d1c1d;
          line-height: 20px;
          font-weight: 400;
          margin: 0;

          &.error {
            color: #ff6e6e;
            font-style: italic;
            font-weight: 400;
            margin-bottom: 0;
          }

          img,
          svg {
            display: inline;
            margin-right: 5px;
          }

          > span {
            display: inline;
          }
        }

        > .badge {
          font-family: Helvetica, "Lato", Arial;
          font-size: $badgeFontSize;
          color: #ffffff;
          line-height: 9px;
          font-weight: 700;
          margin: 0;
          width: fit-content;
          block-size: fit-content;
          border-radius: 10px;
          padding: 4px 8px;
          padding-bottom: 6px;
          max-width: 65px;
        }
        > .badge.badge-pass {
          background-color: #41b4b4;
        }
        > .badge.badge-fail {
          background-color: #ff6e6e;
        }
        > .badge.badge-na {
          background-color: #907aa0;
        }
      }
    }
  }
}

.lender-notes {
  border: 0;
  margin-bottom: 20px;

  > p {
    font-family: Helvetica, "Lato", Arial;
    font-size: $fontSize;
    color: #1d1c1d;
    line-height: 20px;
    font-weight: 400;
    margin: 0;
    border: 0;
  }
}
