@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(./app/fonts/Helvetica-Regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(./app/fonts/Helvetica-Bold.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(./app/fonts/Helvetica-Light.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(./app/fonts/Helvetica-Oblique.woff) format("woff");
  font-weight: 400;
  font-style: oblique;
}
