.deposite-slider {
  -webkit-appearance: none;
  width: 100%;
  appearance: none;
  background: #f2f7fc;
  background-image: linear-gradient(#579bcb, #579bcb);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  height: 5px;
  position: relative;
  z-index: 2;
}

.deposite-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  z-index: 2;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #ffffff;
  border: 4px solid #579bcb;
  cursor: ew-resize;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.157944);
  box-sizing: border-box;
}

.deposite-slider::-moz-range-thumb {
  position: relative;
  z-index: 2;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #ffffff;
  border: 4px solid #579bcb;
  cursor: ew-resize;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.157944);
  box-sizing: border-box;
}

/* Input Track */
.deposite-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.deposite-slider::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.deposite-slider::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.deposite-slider-mark {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  transform: translateY(-80%);
  padding: 0 calc(23px / 2);
  display: flex;
  justify-content: space-between;
  z-index: 1;
}
