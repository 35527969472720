html {
  background-color: #eeeaf2;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-scrollable {
  overflow-x: overlay;
}

.custom-scrollable::-webkit-scrollbar {
  display: block;
  width: 0.4rem;
}

.custom-scrollable::-webkit-scrollbar-button {
  display: none;
}

.custom-scrollable::-webkit-scrollbar-thumb {
  background-color: #6f5f7b3d;
  border-radius: 3px;
  cursor: pointer;
}

@-moz-document url-prefix() {
  .custom-scrollable {
    overflow-x: scroll;
  }
}

.required:after {
  content: " *";
  color: #f75454;
}
