@-webkit-keyframes preloadAnimation {
  from {
    background-position: -310px 0;
  }
  to {
    background-position: 590px 0;
  }
}

@keyframes preloadAnimation {
  from {
    background-position: -310px 0;
  }
  to {
    background-position: 590px 0;
  }
}
@-webkit-keyframes preloadAnimation-2 {
  from {
    background-position: -310px 0;
  }
  to {
    background-position: 90px 0;
  }
}
@keyframes preloadAnimation-2 {
  from {
    background-position: -310px 0;
  }
  to {
    background-position: 90px 0;
  }
}
@keyframes scale {
  0% {
    transform: scaleY(0);
  }
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0);
  }
}
.animated-long {
  background: linear-gradient(to right, #e4edf7 8%, #d0e0f1 18%, #e4edf7 33%);
  background-size: 1000px 0.75rem;
  -webkit-animation: preloadAnimation 1.5s linear forwards infinite;
  animation: preloadAnimation 1.5s linear forwards infinite;
}

.animated-short {
  background: linear-gradient(to right, #e4edf7 8%, #d0e0f1 18%, #e4edf7 33%);
  background-size: 200px 0.75rem;
  -webkit-animation: preloadAnimation-2 3s linear forwards infinite;
  animation: preloadAnimation-2 3s linear forwards infinite;
}

.animated-long-black {
  background: linear-gradient(to right, #cdc3d4 8%, #c1b4c9 18%, #cdc3d4 33%);
  background-size: 1000px 0.75rem;
  -webkit-animation: preloadAnimation 1.5s linear forwards infinite;
  animation: preloadAnimation 1.5s linear forwards infinite;
}

.animated-short-black {
  background: linear-gradient(to right, #cdc3d4 8%, #c1b4c9 18%, #cdc3d4 33%);
  background-size: 200px 0.75rem;
  -webkit-animation: preloadAnimation-2 3s linear forwards infinite;
  animation: preloadAnimation-2 3s linear forwards infinite;
}

.animated-bar {
  transform-origin: center center;
  transform: scaleY(0);
  -webkit-animation: scale 2s cubic-bezier(0.46, 0.03, 0.52, 0.96) forwards
    infinite;
  animation: scale 2s cubic-bezier(0.46, 0.03, 0.52, 0.96) forwards infinite;
  animation-delay: calc(var(--order) * 100ms);
}
